import React, { Component } from 'react';
import { ApiReq, notySend, getApiToken, getApiDomain, isJson } from '../components/function';
import Loading from './Loading';
import CampaignsSelect from '../components/CampaignsSelect';

interface DublicatesFingerprintsItem {
  id:string,
  lead_date_create:string,
  fingerprint_value:string,
  broker_lead_id:string
}

interface ListDublicatesFingerprintsItem {
  fingerprint: string;
  minDate: string;
  maxDate: string;
  items: DublicatesFingerprintsItem[]
}

export default class CheckFingerprintsPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        loadBtn:false,
        campaign_id:null,
        listDublicatesFingerprint:[]
      }
    }
  
    componentDidMount(){
      
    }
  
  
    GetReportByFingerPrints = () =>{
      if(this.state.campaign_id != null){
        this.setState({
          loadBtn:true
        })
        ApiReq('/leads/check_fingerprints_by_campaigns/', {campaign_id:this.state.campaign_id}).then(res => {
          if(res != false){
            if(res.success == true){
              this.setState({
                listDublicatesFingerprint:this.GenerateDuplicateFingerprintsList(res.data)
              })
            }
            else{
              notySend('error', 'error #'+res.error);
            }
          }
          this.setState({
            loadBtn:false
          })
        })
      }
      else{
        notySend('error', 'select campaign');
      }
    }

    GenerateDuplicateFingerprintsList(data: DublicatesFingerprintsItem[]): ListDublicatesFingerprintsItem[] {
      const fingerprintMap: { [key: string]: DublicatesFingerprintsItem[] } = {};
    
      data.forEach((item) => {
        if (!fingerprintMap[item.fingerprint_value]) {
          fingerprintMap[item.fingerprint_value] = [];
        }
        fingerprintMap[item.fingerprint_value].push(item);
      });
    
      
      const result: ListDublicatesFingerprintsItem[] = Object.keys(fingerprintMap).map((fingerprint) => {
        const items = fingerprintMap[fingerprint];
        
        const minDate = items.reduce(
          (min, item) => (item.lead_date_create < min ? item.lead_date_create : min),
          items[0].lead_date_create
        );
        
        const maxDate = items.reduce(
          (max, item) => (item.lead_date_create > max ? item.lead_date_create : max),
          items[0].lead_date_create
        );
    
        return {
          fingerprint,
          minDate,
          maxDate,
          items,
        };
      });
    
      return result;
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body' style={{minHeight:500}}>
              <label>Select campaign</label>
              <CampaignsSelect onChange={(c:any)=>{this.setState({campaign_id:c.value})}}></CampaignsSelect>
              <br></br>
              <button onClick={()=>{this.GetReportByFingerPrints()}} disabled={this.state.loadBtn} className='btn btn-primary form-control'>{this.state.loadBtn?<div className="spinner-border text-warning spinner-border-sm"></div>:null} Load report by fingerprints</button>
              <hr></hr>
              {this.state.listDublicatesFingerprint.map((item:ListDublicatesFingerprintsItem) => {
                return(
                  <div key={item.fingerprint} className='col'>
                    <div className='card' style={{minWidth:126}}>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col text-center'>
                            <label>Fingerprint</label>
                            <div>{item.fingerprint}</div>
                          </div>
                          <div className='col text-center'>
                            <label>Date from - to</label>
                            <div>{item.minDate} - {item.maxDate}</div>
                          </div>
                          <div className='col text-center'>
                            <label>Number of elements</label>
                            <div>{item.items.length}</div>
                          </div>
                        </div>
                        <hr></hr>
                        <div className='card'>
                          <div className='card-body'>
                            <div className='table-responsive' style={{fontSize:12}}>
                              <table className="table text-white text-center">
                                <thead>
                                  <th>id</th>
                                  <th>date create</th>
                                  <th>fingerprint</th>
                                  <th>broker lead id</th>
                                  <th>show</th>
                                </thead>
                                <tbody>
                                  {item.items.map((item2:DublicatesFingerprintsItem) => {
                                    return (
                                      <tr>
                                        <td>{item2.id}</td>
                                        <td>{item2.lead_date_create}</td>
                                        <td>{item2.fingerprint_value}</td>
                                        <td>{item2.broker_lead_id}</td>
                                        <td><a href={'/lead/'+item2.id+'/'} target='_blank' className="text-primary"> <i className="material-icons arrow_circle_up" style={{rotate:'90deg'}}></i></a></td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      );
    }
}

