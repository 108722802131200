import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath, isJson } from '../components/function';
import PartnersSelect from '../components/PartnersSelect';
import BrokersSelect from '../components/BrokersSelect';
import StatusSelectList from '../components/StatusSelectList';
import Loading from '../pages/Loading';
import GeoListSelect from '../components/GeoListSelect';
import CustomModal from '../components/CustomModal';
import Select from 'react-select';
const options = [
  { value: true, label: 'true' },
  { value: false, label: 'false' },
]



export default class LeadPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        lead_data:{name:'name'},
        history_status:[],
        l_status:{value:null, label:'status'},
        l_ftd:{ value: false, label: 'false' },
        l_no_replace_status:false,
        customModal:false,
        btn_disabled:true,
        btn_disabled_loader:<div className="spinner-border text-warning spinner-border-sm"></div>,
        page_load:false,
        fingerprintModal:false,
        fingerprintData:{}
      }
    }
  
    componentDidMount(){
      this.GetList();
      this.GetLeadStatusHistory(getUrlPath()[1]);
    }
  
    GetList = () => {
      ApiReq('/leads/get_list/', {lead_id:getUrlPath()[1]}).then(res => {
        if(res != false){
          if(res.success == true){
            // console.log(res.data[0])
            let ftd = new Boolean(res.data[0].ftd);
            let lead_data = res.data[0];
            if(lead_data.broker_last_answer != '' && isJson(lead_data.broker_last_answer)){
                lead_data.broker_last_answer = JSON.parse(lead_data.broker_last_answer);
                lead_data.broker_last_answer = JSON.stringify(lead_data.broker_last_answer, undefined, 4);
            }
            if(lead_data.partner_add_lead_answer != '' && isJson(lead_data.partner_add_lead_answer)){
                lead_data.partner_add_lead_answer = JSON.parse(lead_data.partner_add_lead_answer);
                lead_data.partner_add_lead_answer = JSON.stringify(lead_data.partner_add_lead_answer, undefined, 4);
            }
            this.setState({
                lead_data:lead_data,
                l_status:{value:res.data[0].status, label:res.data[0].status},
                l_ftd:{value:res.data[0].ftd, label:ftd.toString()},
                l_no_replace_status:res.data[0].no_replace_status,
                btn_disabled:false,
                page_load:true,
                fingerprintData:JSON.stringify(JSON.parse(lead_data.fingerprint_data), undefined, 4)
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    GetLeadStatusHistory = (lead_id:any) => {
        ApiReq('/leads/get_status_history/', {lead_id:getUrlPath()[1]}).then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        history_status:res.data
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    UpdateLead = () => {
        this.setState({
            btn_disabled:true,
        })
        ApiReq('/leads/update_current_lead/', {lead_id:getUrlPath()[1]}).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success!');
                    setTimeout(()=>{
                        window.location.reload();
                    }, 1000)
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
            this.setState({
                btn_disabled:false,
            })
        })
    }

    DeleteLead = () => {
        ApiReq('/leads/delete_lead/', {lead_id:getUrlPath()[1]}).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success!');
                    setTimeout(()=>{
                        urlNavigate('/leads/');
                    }, 1000)
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    UpdateLeadFromAdmin = () => {
        this.setState({
            btn_disabled:true,
        })
        //console.log({lead_id:getUrlPath()[1], new_status:this.state.l_status.value, new_ftd:this.state.l_ftd.value, no_replace_status:this.state.l_no_replace_status})
        ApiReq('/leads/update_lead_from_admin/', {lead_id:getUrlPath()[1], new_status:this.state.l_status.value, new_ftd:this.state.l_ftd.value, no_replace_status:this.state.l_no_replace_status}).then(res => {
            if(res != false){
                if(res.success == true){
                    notySend('success', 'success!');
                    setTimeout(()=>{
                        window.location.reload();
                    }, 1000)
                    
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
            this.setState({
                btn_disabled:false,
            })
        })
    }

    ReturnFrodAndErrorInfo = (type:any) => {
        if(type == 'check_frod_ip'){
            if(this.state.lead_data.check_frod_ip == true){
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-success">
                        success!
                    </div>
                )
            }
            else{
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-warning">
                        *{this.state.lead_data.check_frod_ip_info}
                    </div>
                )
            }
        }
        if(type == 'check_frod_referer'){
            if(this.state.lead_data.check_frod_referer == true){
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-success">
                        system check froud referer return success!
                    </div>
                )
            }
            else{
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-warning">
                        *{this.state.lead_data.check_frod_referer_info}
                    </div>
                )
            }
        }
        if(type == 'check_frod_useragent'){
            if(this.state.lead_data.check_frod_useragent == true){
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-success">
                        system check froud useragent return success!
                    </div>
                )
            }
            else{
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-warning">
                        *{this.state.lead_data.check_frod_useragent_info}
                    </div>
                )
            }
        }
        if(type == 'check_frod_autologin'){
            if(this.state.lead_data.check_frod_autologin == true){
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-success">
                        success!
                    </div>
                )
            }
            else{
                if(this.state.check_frod_autologin == true){
                    return(
                        <div style={{padding:6, margin:0}} className="form-control text-success">
                            success
                        </div>
                    )
                }
                else{
                    if(this.state.check_frod_autologin_info != ''){
                        return(
                            <div style={{padding:6, margin:0}} className="form-control text-warning">
                                *{this.state.lead_data.check_frod_autologin_info}
                            </div>
                        )
                    }
                    else{
                        return(
                            <div style={{padding:6, margin:0}} className="form-control text-warning">
                                *{'autologin url postback info undefined!'}
                            </div>
                        )
                    }
                }
            }
        }
        if(type == 'broker_send_lead'){
            if(this.state.lead_data.broker_send_lead == true){
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-success">
                        success!
                    </div>
                )
            }
            else{
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-warning">
                        This user has not yet been sent to the broker because the campaign settings have a limit on sending by time, days of the week and time zone (the user will be automatically sent to the broker according to the time frame set in the campaign settings)!
                    </div>
                )
            }
        }
        if(type == 'error'){
            if(this.state.lead_data.error != null){
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-danger">
                        {this.state.lead_data.error}
                    </div>
                )
            }
            else{
                return(
                    <div style={{padding:6, margin:0}} className="form-control text-success">
                        success!
                    </div>
                )
            }
        }
        if(type == 'btn_update'){
            if(this.state.lead_data.error == null){
                return(
                    <div>
                        <button disabled={this.state.btn_disabled} onClick={()=>{this.UpdateLead()}} className='btn btn-outline-primary form-control'>{this.state.btn_disabled ? this.state.btn_disabled_loader : null} update lead from broker</button>
                        <p></p>
                    </div>
                )
            }
        }
    }

    HideModal = () => {
        this.setState({
          customModal:false,
          fingerprintModal:false
        })
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
              {(this.state.page_load == true) ? null:<div className='text-center'><Loading></Loading><div>loading data...</div></div>}
              <div className='row'>
                <div className='col' style={{minWidth:250}}>
                    <div className='text-center'>
                        <h5>Lead data</h5>
                        <hr></hr>
                    </div>
                    <div className='form-group'>
                        <label>id</label>
                        <input defaultValue={this.state.lead_data.id} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>status</label>
                        <input checked={this.state.l_no_replace_status} onChange={(t)=>{if(this.state.l_no_replace_status == false){this.setState({l_no_replace_status:true})}else{this.setState({l_no_replace_status:false})}}} className="form-check-input" style={{marginLeft:5}} type={'checkbox'}></input> (don't update status anymore)
                        <StatusSelectList value={this.state.l_status} onChange={(val:any)=>{this.setState({l_status:val})}}></StatusSelectList>
                        {/* <input defaultValue={this.state.lead_data.status} className='form-control' disabled={true}></input> */}
                    </div>
                    <div className='form-group'>
                        <label>ftd</label>
                        <Select value={this.state.l_ftd} onChange={(val:any)=>{this.setState({l_ftd:val})}} options={options}></Select>
                        {/* <input defaultValue={this.state.lead_data.ftd} className='form-control' disabled={true}></input> */}
                    </div>
                    <div className='form-group'>
                        <label>ftd date</label>
                        <input defaultValue={this.state.lead_data.ftd_date} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>first_name</label>
                        <input defaultValue={this.state.lead_data.first_name} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>last_name</label>
                        <input defaultValue={this.state.lead_data.last_name} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>email</label>
                        <input defaultValue={this.state.lead_data.email} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>phone</label>
                        <input defaultValue={this.state.lead_data.phone} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>user_ip</label>
                        <input defaultValue={this.state.lead_data.lead_ip} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>funnel</label>
                        <input defaultValue={this.state.lead_data.funnel} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>country</label>
                        <input defaultValue={this.state.lead_data.country} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>lang</label>
                        <input defaultValue={this.state.lead_data.lang} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>lead date create</label>
                        <input defaultValue={this.state.lead_data.lead_date_create} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>broker lead id</label>
                        <input defaultValue={this.state.lead_data.broker_lead_id} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>broker last update date</label>
                        <input defaultValue={this.state.lead_data.broker_last_update_date} className='form-control' disabled={true}></input>
                    </div>
                    <div className='form-group'>
                        <label>utm comment</label>
                        <textarea style={{minHeight:100}} defaultValue={this.state.lead_data.utm_comment} className='form-control' disabled={true}></textarea>
                    </div>
                    <div className='form-group'>
                        <label>utm content</label>
                        <textarea style={{minHeight:100}} defaultValue={this.state.lead_data.utm_content} className='form-control' disabled={true}></textarea>
                    </div>
                </div>
                <div className='col'>
                    <div className='text-center'>
                        <h5>Fraud & errors <span onClick={()=>{this.setState({fingerprintModal:true})}} style={{float:'right'}} className='badge bg-primary c_p'>fingerprint data</span></h5>
                        
                        <hr></hr>
                    </div>
                    <label>fraud check (check ip system)</label>
                    {this.ReturnFrodAndErrorInfo('check_frod_ip')}
                    <label>fraud check (autologin system)</label>
                    {this.ReturnFrodAndErrorInfo('check_frod_autologin')}
                    <label>fraud check (check referer system)</label>
                    {this.ReturnFrodAndErrorInfo('check_frod_referer')}
                    <label>fraud check (check useragent (bot) system)</label>
                    {this.ReturnFrodAndErrorInfo('check_frod_useragent')}
                    {/* <label>send lead</label>
                    {this.ReturnFrodAndErrorInfo('broker_send_lead')} */}
                    <label>errors</label>
                    {this.ReturnFrodAndErrorInfo('error')}
                    <div className='text-center'>
                        <br></br>
                        <h5>Lead rout</h5>
                        <hr></hr>
                    </div>
                    <ul className="list-group text-white" style={{borderColor:'white'}}>
                        <li style={{background:'transparent'}} className="list-group-item text-white d-flex justify-content-between align-items-center">
                            campaign
                            <span className="badge badge-primary badge-pill"><a style={{textDecoration:'none'}} href={'/edit_campaign/'+this.state.lead_data.campaign+'/'}>{this.state.lead_data.campaign_name}</a></span>
                        </li>
                        <li style={{background:'transparent'}} className="list-group-item text-white d-flex justify-content-between align-items-center">
                            partner
                            <span className="badge badge-primary badge-pill"><a style={{textDecoration:'none'}} href={'/edit_user/'+this.state.lead_data.partner+'/'}>{this.state.lead_data.partner_name}</a></span>
                        </li>
                        <li style={{background:'transparent'}} className="list-group-item text-white d-flex justify-content-between align-items-center">
                            broker
                            <span className="badge badge-primary badge-pill"><a style={{textDecoration:'none'}} href={'/edit_broker/'+this.state.lead_data.broker+'/'}>{this.state.lead_data.broker_name}</a></span>
                        </li>
                    </ul>
                    <div className='text-center'>
                        <br></br>
                        <h5>Status history</h5>
                        <hr></hr>
                    </div>
                    <ul className="list-group text-white" style={{borderColor:'white'}}>
                        {this.state.history_status.map((item:any) => {
                            return(
                                <li key={item.id} style={{background:'transparent'}} className="list-group-item text-white d-flex justify-content-between align-items-center">
                                    <span className='text-success'>{item.date +' : status "'+item.status+'"'}</span>
                                </li>
                            )
                        })}
                    </ul>
                    <div className='text-center'>
                        <br></br>
                        <h5>Broker/Partner answer (add lead)</h5>
                        <hr></hr>
                    </div>
                    <label>Broker answer</label>
                    <textarea  defaultValue={this.state.lead_data.broker_last_answer} style={{minHeight:180}} className='form-control'></textarea>
                    <label>Partner answer</label>
                    <textarea defaultValue={this.state.lead_data.partner_add_lead_answer} style={{minHeight:180}} className='form-control'></textarea>
                </div>
              </div>
              <hr></hr>
              
              <button disabled={this.state.btn_disabled} onClick={()=>{this.UpdateLeadFromAdmin()}} className='btn btn-outline-info form-control'>{this.state.btn_disabled ? this.state.btn_disabled_loader : null} save lead</button>
              <p></p>
              {this.ReturnFrodAndErrorInfo('btn_update')}
              <button disabled={this.state.btn_disabled} onClick={()=>{this.setState({customModal:true})}} className='btn btn-outline-danger form-control'>{this.state.btn_disabled ? this.state.btn_disabled_loader : null} delete</button>
            </div>
          </div>
          <CustomModal visible={this.state.customModal} header={'Attention!'} callback={this.HideModal}>
            <div className='text-center'>
              <h4>Confirm the deletion request!</h4>
            </div>
            <br></br>
            <button onClick={()=>{this.DeleteLead()}} className='btn btn-outline-danger form-control'>delete lead</button>
          </CustomModal>
          <CustomModal size={'big'} visible={this.state.fingerprintModal} header={'Fingerprint data'} callback={this.HideModal}>
            <div className='text-center'>
              <textarea value={this.state.fingerprintData} className='form-control' style={{height:500}}></textarea>
            </div>
            <br></br>
          </CustomModal>
        </div>
      );
    }
}

