import React, { Component } from 'react';
import { IonContent } from '@ionic/react';
// import '../components/css/style.css';
// import '../components/css/bootstrap.min.css';
import '../components/css/bootstrap-extended.css';
// if(window.localStorage.getItem(window.location.origin+'_pixelcrm_color_mode') == 'dark'){
//     require('../components/css/bootstrap.min.css');
//     require('../components/css/bootstrap-extended.css');
// }
import '../components/css/icons.css';
import '../components/css/google-icons.css';
// import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/js/bootstrap.min.js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PayModal from '../components/PayModal';
import StartSettingModal from '../components/StartSettingModal';
import MainPage from '../pages/MainPage';
import Loading from '../pages/Loading';
import Page404 from './Page404';
import LeadsPage from './LeadsPage';
import LeadPage from './LeadPage';
import Login from './LoginPage';
import BrokersPage from './BrokersPage';
import AddBrokerPage from './AddBrokerPage';
import EditBrokerPage from './EditBrokerPage';
import EditIntegCodePage from './EditIntegCodePage';

import UsersPage from './UsersPage';
import AddUserPage from './AddUserPage';
import EditUserPage from './EditUserPage';

import PartnersPage from './PartnersPage';
import AddPartnerPage from './AddPartnerPage';

import CampaignsPage from './CampaignsPage';
import AddCampaignPage from './AddCampaignPage';
import EditCampaignPage from './EditCampaignPage';

import ServiceConnectPage from './ServiceConnectPage';
import SendboxCodePage from './SendboxCodePage';
import SenderLeadPage from './SenderLeadPage';
import SettingsPage from './SettingsPage';
import LogsPage from './LogsPage';
import IntegrationPage from './IntegrationPage';
import CheckFingerprintsPage from './CheckFingerprintsPage';
import { getUrlPath, getCheckerDomain, ApiReq, getUserData } from '../components/function';

let pixelcrm_user_data = localStorage.getItem(window.location.origin+'_pixelcrm_user_data');

if(localStorage.getItem(window.location.origin+'_pixelcrm_theme') == null){
    var element = document.createElement("link");
    element.setAttribute("rel", "stylesheet");
    element.setAttribute("type", "text/css");
    if(window.location.origin.indexOf('localhost') != -1){
        element.setAttribute("href", "http://argocpm:8888/client/build/css/dark/bootstrap.min.css");
    }
    else{
        element.setAttribute("href", window.location.origin+"/client/build/css/dark/bootstrap.min.css");
    }
    document.getElementsByTagName("head")[0].appendChild(element);
    
    var element = document.createElement("link");
    element.setAttribute("rel", "stylesheet");
    element.setAttribute("type", "text/css");
    if(window.location.origin.indexOf('localhost') != -1){
        element.setAttribute("href", "http://argocpm:8888/client/build/css/dark/style.css");
    }
    else{
        element.setAttribute("href", window.location.origin+"/client/build/css/dark/style.css");
    }
    
    document.getElementsByTagName("head")[0].appendChild(element);
}
else{
    if(localStorage.getItem(window.location.origin+'_pixelcrm_theme') == 'dark'){
        var element = document.createElement("link");
        element.setAttribute("rel", "stylesheet");
        element.setAttribute("type", "text/css");
        if(window.location.origin.indexOf('localhost') != -1){
            element.setAttribute("href", "http://argocpm:8888/client/build/css/dark/bootstrap.min.css");
        }
        else{
            element.setAttribute("href", window.location.origin+"/client/build/css/dark/bootstrap.min.css");
        }
        document.getElementsByTagName("head")[0].appendChild(element);
        
        var element = document.createElement("link");
        element.setAttribute("rel", "stylesheet");
        element.setAttribute("type", "text/css");
        if(window.location.origin.indexOf('localhost') != -1){
            element.setAttribute("href", "http://argocpm:8888/client/build/css/dark/style.css");
        }
        else{
            element.setAttribute("href", window.location.origin+"/client/build/css/dark/style.css");
        }
        document.getElementsByTagName("head")[0].appendChild(element);
    }
    else{
        var element = document.createElement("link");
        element.setAttribute("rel", "stylesheet");
        element.setAttribute("type", "text/css");
        if(window.location.origin.indexOf('localhost') != -1){
            element.setAttribute("href", "http://argocpm:8888/client/build/css/white/bootstrap.min.css");
        }
        else{
            element.setAttribute("href", window.location.origin+"/client/build/css/white/bootstrap.min.css");
        }
        
        document.getElementsByTagName("head")[0].appendChild(element);
        
        var element = document.createElement("link");
        element.setAttribute("rel", "stylesheet");
        element.setAttribute("type", "text/css");
        if(window.location.origin.indexOf('localhost') != -1){
            element.setAttribute("href", "http://argocpm:8888/client/build/css/white/style.css");
        }
        else{
            element.setAttribute("href", window.location.origin+"/client/build/css/white/style.css");
        }
        
        document.getElementsByTagName("head")[0].appendChild(element);
    }
}


export default class Home extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
          page:<Loading></Loading>,
          page_full_load:true,
          pay_modal:null,
          setting_modal:null
      }
    }
  
    componentDidMount(){
        // console.log(getUserData('role'))
        this.StartRout();
        this.CheckAccessToSystem();
        this.LoadCacheSelectLists();
        this.CheckAndUpdateAuthUserData();

        // window.onload = ()=>{
        //     this.setState({
        //         page_full_load:true
        //     })
        // }
                
        // window.addEventListener("load", (event) => {
        //     this.setState({
        //         page_full_load:true
        //     })
        // });

    }


    CheckAccessToSystem = () => {
        let last_req_info:any = localStorage.getItem('subscrube_info_from_checker_last_req');
        if(last_req_info != null){
            last_req_info = JSON.parse(last_req_info);
            if(last_req_info.answer_req.success == true){
                if((Number(last_req_info.time_req) + 1800000) < Date.now()){
                    this.CheckAccessToSystemReq();
                }
            }
            else{
                this.CheckAccessToSystemReq();
            }
        }
        else{
            this.CheckAccessToSystemReq();
        }
        
    }

    CheckAccessToSystemReq = () => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body:JSON.stringify({system_url:window.location.host}),
        };
        
        fetch(getCheckerDomain()+'/customers/check_access_to_system/', requestOptions)
        .then(response => response.json())
        .then(data => {
            setTimeout(()=>{

                localStorage.setItem('subscrube_info_from_checker_last_req', JSON.stringify({time_req:Date.now(), answer_req:data}));
                console.log('access_to_system '+localStorage.getItem('subscrube_info_from_checker_last_req'));

                if(data.success == false){
                    this.setState({
                        pay_modal:<PayModal></PayModal>
                    })
                }
                else{
                    localStorage.setItem('subscrube_info_from_checker', JSON.stringify(data.data));
                    this.CheckSettingModal();
                }
            }, 2000)
        });
    }

    CheckSettingModal = () => {
        ApiReq('/system_config/get_db_config_access/').then(res => {
            console.log('db setting '+JSON.stringify(res))
            if(res != false){
                if(res.connect == false){
                    this.setState({
                    setting_modal:<StartSettingModal></StartSettingModal>
                    })
                }
            }
        })
    }

    LoadCacheSelectLists = () => {
        if(window.location.href.indexOf('login') == -1){
            ApiReq('/system_config/get_users_cache/').then(res => {
                if(res != false){
                    if(res.success == true){
                        localStorage.setItem(window.location.origin+'_pixelcrm_cache_select_data', JSON.stringify(res.data));
                    }
                }
            })
        }
        
    }

    CheckAndUpdateAuthUserData = () => {
        if(window.location.href.indexOf('login') == -1){
            if(localStorage.getItem(window.location.origin+'_pixelcrm_check_last_auth_date') != null){
                if(Number(localStorage.getItem(window.location.origin+'_pixelcrm_check_last_auth_date')) < Date.now()+600000){
                    setTimeout(()=>{
                        ApiReq('/auth/check_auth/').then(res => {
                            if(res != false){
                                if(res.success != false){
                                    localStorage.setItem(window.location.origin+'_pixelcrm_check_last_auth_date', String(Date.now()));
                                    localStorage.setItem(window.location.origin+'_pixelcrm_user_data', JSON.stringify(res.data))
                                }
                            }
                        })
                    }, 1000)
                }
            }
            else{
                localStorage.setItem(window.location.origin+'_pixelcrm_check_last_auth_date', String(Date.now()));
            }
        }
    }
  
    
    StartRout = () => {
        let url:any = getUrlPath();
        
        let routs = [
            {
                rout:'login', 
                page_name:'login', 
                page:<Login></Login>,
                roles:[]
            },
            {
                rout:'main', 
                page_name:'Main', 
                page:<><Header></Header><Navigation></Navigation><MainPage></MainPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'leads', 
                page_name:'Leads', 
                page:<><Header></Header><Navigation></Navigation><LeadsPage></LeadsPage><Footer></Footer></>,
                roles:[]
            },
            {
                rout:'brokers', 
                page_name:'Brokers', 
                page:<><Header></Header><Navigation></Navigation><BrokersPage></BrokersPage><Footer></Footer></>,
                roles:['admin', 'integrator', 'brand manager']
            },
            {
                rout:'add_broker', 
                page_name:'Add broker', 
                page:<><Header></Header><Navigation></Navigation><AddBrokerPage></AddBrokerPage><Footer></Footer></>,
                roles:['admin', 'integrator', 'brand manager']
            },
            {
                rout:'edit_broker', 
                page_name:'Edit broker', 
                page:<><Header></Header><Navigation></Navigation><EditBrokerPage></EditBrokerPage><Footer></Footer></>,
                roles:['admin', 'integrator', 'brand manager']
            },
            {
                rout:'edit_integration_code', 
                page_name:'Edit Integration Code', 
                page:<><Header></Header><Navigation></Navigation><EditIntegCodePage></EditIntegCodePage><Footer></Footer></>,
                roles:['admin', 'integrator', 'brand manager']
            },
            {
                rout:'users', 
                page_name:'Users', 
                page:<><Header></Header><Navigation></Navigation><UsersPage></UsersPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'add_user', 
                page_name:'Add user', 
                page:<><Header></Header><Navigation></Navigation><AddUserPage></AddUserPage><Footer></Footer></>,
                roles:['admin', 'manager']
            },
            {
                rout:'edit_user', 
                page_name:'Edit user', 
                page:<><Header></Header><Navigation></Navigation><EditUserPage></EditUserPage><Footer></Footer></>,
                roles:['admin', 'manager']
            },
            {
                rout:'partners', 
                page_name:'Partners', 
                page:<><Header></Header><Navigation></Navigation><PartnersPage></PartnersPage><Footer></Footer></>,
                roles:['admin', 'manager']
            },
            {
                rout:'add_partner', 
                page_name:' Add Partners', 
                page:<><Header></Header><Navigation></Navigation><AddPartnerPage></AddPartnerPage><Footer></Footer></>,
                roles:['admin', 'manager']
            },
            {
                rout:'campaigns', 
                page_name:'Campaigns', 
                page:<><Header></Header><Navigation></Navigation><CampaignsPage></CampaignsPage><Footer></Footer></>,
                roles:['admin', 'manager']
            },
            {
                rout:'add_campaign', 
                page_name:'Add campaign', 
                page:<><Header></Header><Navigation></Navigation><AddCampaignPage></AddCampaignPage><Footer></Footer></>,
                roles:['admin', 'manager']
            },
            {
                rout:'service_connect', 
                page_name:'Service connect', 
                page:<><Header></Header><Navigation></Navigation><ServiceConnectPage></ServiceConnectPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'sendbox_code', 
                page_name:'Sendbox code', 
                page:<><Header></Header><Navigation></Navigation><SendboxCodePage></SendboxCodePage><Footer></Footer></>,
                roles:['admin', 'integrator']
            },
            {
                rout:'sender_lead', 
                page_name:'Sender lead', 
                page:<><Header></Header><Navigation></Navigation><SenderLeadPage></SenderLeadPage><Footer></Footer></>,
                roles:['admin', 'integrator', 'brand manager']
            },
            {
                rout:'settings', 
                page_name:'Settings', 
                page:<><Header></Header><Navigation></Navigation><SettingsPage></SettingsPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'logs', 
                page_name:'Logs', 
                page:<><Header></Header><Navigation></Navigation><LogsPage></LogsPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'edit_campaign', 
                page_name:'Edit campaign', 
                page:<><Header></Header><Navigation></Navigation><EditCampaignPage></EditCampaignPage><Footer></Footer></>,
                roles:['admin', 'manager']
            },
            {
                rout:'lead', 
                page_name:'lead', 
                page:<><Header></Header><Navigation></Navigation><LeadPage></LeadPage><Footer></Footer></>,
                roles:['admin', 'manager', 'brand manager']
            },
            {
                rout:'check_fingerprints',  
                page_name:'Check fingerprints', 
                page:<><Header></Header><Navigation></Navigation><CheckFingerprintsPage></CheckFingerprintsPage><Footer></Footer></>,
                roles:['admin']
            },
            {
                rout:'integration', 
                page_name:'Integration', 
                page:<><Header></Header><Navigation></Navigation><IntegrationPage></IntegrationPage><Footer></Footer></>,
                roles:[]
            },
        ];

        
        setTimeout(()=>{
            if(pixelcrm_user_data === null && url[0] != 'login'){
                window.location.replace('/login/');
            }
        }, 2500)
        
        if(url.length == 0){
            if(pixelcrm_user_data == null){
                window.location.replace('/login/');
            }
            else{
                window.location.replace('/main/');
            }
        }
        else{
            let isset_page = false;
            for(let i=0; i < routs.length; i++){
                if(url[0] == routs[i].rout){
                    if(getUserData('role') != false){
                        if(routs[i].roles.length != 0){
                            if(routs[i].roles.indexOf(getUserData('role')) != -1){
                                isset_page = true;
                                localStorage.setItem('page_name', routs[i].page_name);
                                this.setState({
                                    page:routs[i].page
                                })
                                break;
                            }
                        }
                        else{
                            isset_page = true;
                            localStorage.setItem('page_name', routs[i].page_name);
                            this.setState({
                                page:routs[i].page
                            })
                            break;
                        }
                    }
                    else{
                        isset_page = true;
                        localStorage.setItem('page_name', routs[i].page_name);
                        this.setState({
                            page:routs[i].page
                        })
                        break;
                    }
                }
            }
            
            if(isset_page == false){
                this.setState({
                    page:<Page404></Page404>
                })
            }
        }
        
    }


    RenderMagicBG = () => {
        return(
            <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice" style={{position:'absolute',  overflow:'visible', width:'100%', height:'100%'}}>
                <defs>
                <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5"><animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(255, 0, 255, 1)"></stop><stop offset="100%" stop-color="rgba(255, 0, 255, 0)"></stop></radialGradient>
                <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5"><animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(255, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(255, 255, 0, 0)"></stop></radialGradient>
                <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5"><animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(0, 255, 255, 1)"></stop><stop offset="100%" stop-color="rgba(0, 255, 255, 0)"></stop></radialGradient>
                <radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5"><animate attributeName="fx" dur="23s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(0, 255, 0, 1)"></stop><stop offset="100%" stop-color="rgba(0, 255, 0, 0)"></stop></radialGradient>
                <radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5"><animate attributeName="fx" dur="24.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(0,0,255, 1)"></stop><stop offset="100%" stop-color="rgba(0,0,255, 0)"></stop></radialGradient>
                <radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5"><animate attributeName="fx" dur="25.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="rgba(255,0,0, 1)"></stop><stop offset="100%" stop-color="rgba(255,0,0, 0)"></stop></radialGradient>
                </defs>
                
                <rect x="13.744%" y="1.18473%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(334.41 50 50)"><animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="7s" repeatCount="indefinite"></animateTransform></rect>
                <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(255.072 50 50)"><animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="12s" repeatCount="indefinite"></animateTransform>
                </rect>
                <rect x="9.00483%" y="14.5733%" width="100%" height="100%" fill="url(#Gradient3)" transform="rotate(139.903 50 50)"><animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate><animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="9s" repeatCount="indefinite"></animateTransform>
                </rect>
            </svg>
        )
    }
    

    RoutRender = () => {
        return this.state.page
    }

  
    render() {
        if(this.state.page_full_load == true){
            return (
                <div style={{overflowY:'scroll'}}>
                    {(window.localStorage.getItem(window.location.origin+'_pixelcrm_magic_bg') == null)?null:this.RenderMagicBG()}   
                    <div id='mainwrapper' className="wrapper" >
                        {this.RoutRender()}
                        <ToastContainer newestOnTop={true} />
                        {this.state.pay_modal}
                        {this.state.setting_modal}
                    </div>
                </div>
              );
        }
        else{
            return (
                <div style={{width:'100%', height:'100%'}}>
                    <Loading center={true}></Loading>
                </div>
            )
        }
    }
}

