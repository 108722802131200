import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate } from '../components/function';
import PartnersSelect from '../components/PartnersSelect';
import BrokersSelect from '../components/BrokersSelect';
import StatusSelectList from '../components/StatusSelectList';
import Loading from '../pages/Loading';
import GeoListSelect from '../components/GeoListSelect';
import LangListSelect from '../components/LangListSelect';
import CampaignsSelect from '../components/CampaignsSelect';
import Select from 'react-select';
import CustomModal from '../components/CustomModal';



export default class LeadsPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        // status:{value:null, label:'select'},
        // broker:{value:null, label:'select'},
        // partner:{value:null, label:'select'},
        type_filter:'standart',
        status:null,
        broker:null,
        partner:null,
        total_analyse:[
          {key:0, name:'Total leads', icon:<i className="material-icons people_alt">&#xea21;</i>, data:<Loading></Loading>},
          {key:1, name:'Accepted', icon:<i className="material-icons person_add_alt_1">&#xef65;</i>, data:<Loading></Loading>},
          {key:2, name:'Rejected', icon:<i className="material-icons person_remove_alt_1">&#xef67;</i>, data:<Loading></Loading>},
          {key:3, name:'Deposits', icon:<i className="material-icons paid">&#xf041;</i>, data:<Loading></Loading>},
          {key:4, name:'CR%', icon:<i className="material-icons people_alt">&#xea21;</i>, data:<Loading></Loading>},
          {key:5, name:'Invalid', icon:<i className="material-icons manage_accounts">&#xf02e;</i>, data:<Loading></Loading>},
          {key:6, name:'No answer', icon:<i className="material-icons phone_disabled">&#xe9cc;</i>, data:<Loading></Loading>},
        ],
        country:null,
        email:'',
        phone:'',
        from_date:'',
        to_date:'',
        user_ip:'',
        ftd:{label:'all', value:null},
        lang:{label:'select', value:null},
        id:'',
        broker_lead_id:'',
        campaign:null,
        funnel:'',
        fraud_filter:null,
        errors_filter:null,
        limit:[0, 20],
        table_data:[],
        table_data_load:false,
        btn_load_more:null,
        customModal:false,
        export_confirm:false,
        export_url:null,
        modal_lead_small_info:false,
        modal_lead_small_info_data:{"id":"","status":"","ftd":false,"first_name":"","last_name":"","email":"","password":"","phone":"","country":"","lang":"","country_id":"","lang_id":"","funnel":"","lead_ip":"","utm_comment":"","utm_content":"","no_replace_status":false,"lead_date_create":"","ftd_date":null,"partner":"","campaign":"","broker":"","manager":"","error":null,"check_frod_ip":false,"check_frod_ip_info":"user ip points to another country (US)","check_frod_autologin":false,"check_frod_autologin_info":"system dont have click from autologin check!","check_frod_referer":false,"check_frod_referer_info":"system dont have click from autologin check!","check_frod_useragent":false,"check_frod_useragent_info":"system dont have click from autologin check!","broker_lead_id":"228","broker_lead_date_create":"2023-06-14 16:42:50","broker_last_answer":"{\"success\":true,\"lead_id\":228}","partner_add_lead_answer":"{\"success\":true,\"lead_id\":527,\"status\":\"New\",\"autologin_url\":\"http://argocpm:8888/api/uploads/autologin/index.php?al_data=eyJsZWFkX2lkIjo1MjcsInVzZXJfaXAiOiIyMDkuMTQyLjY4LjI5IiwibmFtZSI6IjNHcnZJIiwibGFzdF9uYW1lIjoiYVBoZ0wiLCJsZWFkX2RhdGVfY3JlYXRlIjoiMjAyMy0wNi0xNCAxNjo0Mjo1MCJ9\"}","broker_last_update_date":"2023-06-14 16:48:16","partner_name":"www33new41","broker_name":"smiletestbroker","campaign_name":"smilecamp","broker_send_lead":true}
      }
    }
  
    componentDidMount(){
      this.GetList('start_load');
      this.SetTypeFilter('');
      // this.GetAnalyticsData();
    }

    SetCurrectDateTime = (param:string, d:any) => {
      let currentdate = new Date();
      let c_dt = d.split('T');
      if(c_dt[1] == currentdate.getHours()+':'+currentdate.getMinutes()){
        c_dt = c_dt[0]+'T00:00';
      }
      else{
        c_dt = d;
      }
      // console.log(c_dt)
      if(param == 'to_date'){
        this.setState({
          to_date:c_dt
        })
      }
      else{
        this.setState({
          from_date:c_dt
        })
      }
      
    }
  
    GetList = (type:string) => {
      
      let from_date = this.state.from_date.replace('T', ' ');
      let to_date = this.state.to_date.replace('T', ' ');
      let limit = this.state.limit;
      if(type == 'start_load'){
        limit = [0, 20];
        this.setState({
          table_data_load:false,
          table_data:[]
        })
      }
      this.setState({
        btn_load_more:false,
      })
      
      ApiReq('/leads/get_list/', {where:[{status:this.state.status}, {broker:this.state.broker}, {partner:this.state.partner}, {country:this.state.country}, {email:this.state.email}, {phone:this.state.phone}, {id:this.state.id}, {broker_lead_id:this.state.broker_lead_id}, {campaign:this.state.campaign}, {lang:this.state.lang}, {funnel:this.state.funnel}, {ftd:this.state.ftd}, {from_date:from_date}, {to_date:to_date}, {fraud:this.state.fraud_filter}, {errors:this.state.errors_filter}], limit:limit}).then(res => {
        
        if(res != false){
          if(res.success == true){
            // console.log(res)
            if(type == 'start_load'){
              this.setState({
                table_data:res.data,
                table_data_load:true,
                btn_load_more:true,
                total_analyse:[
                  {key:0, name:'Total leads', icon:<i className="material-icons people_alt">&#xea21;</i>, data:res.analytics_data.data.total_leads},
                  {key:1, name:'Accepted', icon:<i className="material-icons person_add_alt_1">&#xef65;</i>, data:res.analytics_data.data.accepted},
                  {key:2, name:'Rejected', icon:<i className="material-icons person_remove_alt_1">&#xef67;</i>, data:res.analytics_data.data.rejected},
                  {key:3, name:'Deposits', icon:<i className="material-icons paid">&#xf041;</i>, data:res.analytics_data.data.deposits},
                  {key:4, name:'CR%', icon:<i className="material-icons people_alt">&#xea21;</i>, data:res.analytics_data.data.cr},
                  {key:5, name:'Invalid', icon:<i className="material-icons manage_accounts">&#xf02e;</i>, data:res.analytics_data.data.invalid},
                  {key:6, name:'No answer%', icon:<i className="material-icons phone_disabled">&#xe9cc;</i>, data:res.analytics_data.data.no_answer},
                ],
              })
            }
            else{
              if(res.data.length > 0){
                let table_data = this.state.table_data;
                for(let i=0; i<res.data.length; i++){
                  table_data.push(res.data[i]);
                }
                this.setState({
                  table_data:table_data,
                  table_data_load:true,
                  btn_load_more:true
                })
              }
              else{
                this.setState({
                  btn_load_more:null,
                  table_data_load:true,
                })
              }
            }
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    GetAnalyticsData = () => {
      ApiReq('/analytics/get_leads_analytics/').then(res => {
        // console.log(res)
        if(res != false){
          if(res.success == true){
              this.setState({
                total_analyse:[
                  {key:0, name:'Total leads', icon:<i className="material-icons people_alt">&#xea21;</i>, data:res.data.total_leads},
                  {key:1, name:'Accepted', icon:<i className="material-icons person_add_alt_1">&#xef65;</i>, data:res.data.accepted},
                  {key:2, name:'Rejected', icon:<i className="material-icons person_remove_alt_1">&#xef67;</i>, data:res.data.rejected},
                  {key:3, name:'Deposits', icon:<i className="material-icons paid">&#xf041;</i>, data:res.data.deposits},
                  {key:4, name:'CR%', icon:<i className="material-icons people_alt">&#xea21;</i>, data:res.data.cr},
                  {key:5, name:'Invalid', icon:<i className="material-icons manage_accounts">&#xf02e;</i>, data:res.data.invalid},
                  {key:6, name:'No answer', icon:<i className="material-icons phone_disabled">&#xe9cc;</i>, data:res.data.no_answer},
                ],
              })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info);
          }
        }
      })
    }

    GetLeadStatusHistory = (lead_id:any) => {
        ApiReq('/leads/get_status_history/', {lead_id:lead_id}).then(res => {
          if(res != false){
            if(res.success == true){
              if(res.data.length > 0){
                let text:any = '';
                // for(let i=0; i<res.data.length; i++){
                //   text = text+res.data[i].date+' - '+res.data[i].status+"\r\n";
                // }
                for(let i=0; i<res.data.length; i++){
                  text = text +'<div>'+res.data[i].date+' - '+res.data[i].status+'<div><hr style="height:0px; margin:0px">' ;
                }
                notySend('success', <div><div dangerouslySetInnerHTML={{ __html: text }} /></div>);
              }
              else{
                notySend('warning', 'lead status history undefined');
              }
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info);
            }
          }
        })
    }

    SetTypeFilter = (type:string) => {
      if(type == ''){
        if(localStorage.getItem(window.location.origin+'_pixelcrm_leads_type_filter') != null){
          this.setState({
            type_filter:localStorage.getItem(window.location.origin+'_pixelcrm_leads_type_filter')
          })
        }
      }
      else{
        this.setState({
          type_filter:type
        })
        localStorage.setItem(window.location.origin+'_pixelcrm_leads_type_filter', type);
      }
    }

    ExportLeads = () => {
      let from_date = this.state.from_date.replace('T', ' ');
      let to_date = this.state.to_date.replace('T', ' ');
      this.setState({export_confirm:null})
      ApiReq('/leads/export_leads_in_csv/', {where:[{status:this.state.status}, {broker:this.state.broker}, {partner:this.state.partner}, {country:this.state.country}, {email:this.state.email}, {phone:this.state.phone}, {id:this.state.id}, {broker_lead_id:this.state.broker_lead_id}, {campaign:this.state.campaign}, {lang:this.state.lang}, {funnel:this.state.funnel}, {ftd:this.state.ftd}, {from_date:from_date}, {to_date:to_date}, {fraud:this.state.fraud_filter}, {errors:this.state.errors_filter}], limit:[0, 99999]}).then(res => {
        if(res != false){
          if(res.success == true){
            notySend('success', 'success');
            this.setState({export_confirm:true, export_url:res.url})
          }
          else{
            notySend('error', 'error' +res.info);
          }
        }
      })
    }

    RenderTableLoader = () => {
      if(this.state.table_data_load == true){
        return null
      }
      else{
        return <div className='text-center'><Loading></Loading></div>
      }
    }

    RenderBtnLoadMore = () => {
      if(this.state.btn_load_more == true){
        return(
          <div className='text-center'><button onClick={()=>{this.setState({limit:[this.state.limit[0]+20, this.state.limit[1]]}); setTimeout(()=>{this.GetList('more_load')}, 200)}} style={{borderRadius:20}} className='btn btn-outline-primary'> {(this.state.btn_load_more == false) ? <div className="spinner-border text-warning spinner-border-sm"></div>: null} load more</button></div>
        )
      }
      else{
        if(this.state.btn_load_more == null){
          return null;
        }
        else{
          return(
            <div className='text-center'><button disabled={true} onClick={()=>{this.setState({limit:[this.state.limit[1]+20, this.state.limit[1]]}); setTimeout(()=>{this.GetList('more_load')}, 200)}} style={{borderRadius:20}} className='btn btn-outline-primary'> {(this.state.btn_load_more == false) ? <div className="spinner-border text-warning spinner-border-sm"></div>: null} load more</button></div>
          )
        }
        
      }
    }

    
    RenderFilter = () => {
      if(getUserData('role') == 'partner' || (getUserData('role') == 'brand manager' && getUserData('leads_display') == 'basic') || (getUserData('role') == 'manager' && getUserData('leads_display') == 'basic')){
        return (
          <>
            <div className='row'>
              {/* <div className='col'>
                <label>Id</label>
                <input value={this.state.id} onInput={(t)=>{this.setState({id:t.currentTarget.value})}}  className='form-control' type={'number'}></input>
              </div> */}
               <div className='col'>
                <label>Status</label>
                <StatusSelectList isMulti={true} value={this.state.status} onChange={(t:any)=>{this.setState({status:t})}}></StatusSelectList>
              </div>
              <div className='col'>
                <label>Email</label>
                <input value={this.state.email} onInput={(t)=>{this.setState({email:t.currentTarget.value})}} className='form-control' type={'email'}></input>
              </div>
              <div className='col'>
                <label>Funnel</label>
                <input value={this.state.funnel} onInput={(t)=>{this.setState({funnel:t.currentTarget.value})}}  className='form-control'></input>
              </div>
              <div className='col'>
                <label>From date</label>
                <input value={this.state.from_date} onChange={(t)=>{this.SetCurrectDateTime('from_date', t.currentTarget.value)}}  className='form-control' type={'datetime-local'}></input>
              </div>
              <div className='col'>
                <label>To date</label>
                <input value={this.state.to_date} onChange={(t)=>{ this.SetCurrectDateTime('to_date', t.currentTarget.value)}} className='form-control' type={'datetime-local'}></input>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label>Ftd</label>
                {/* <PartnersSelect value={this.state.partner} onChange={(t:any)=>{this.setState({partner:t})}}></PartnersSelect> */}
                <Select value={this.state.ftd} onChange={(t:any)=>{this.setState({ftd:t})}} options={[{label:'all', value:null}, {label:'true', value:1}, {label:'false', value:0}]}></Select>
              </div>
              <div className='col'>
                <label>Geo</label>
                <GeoListSelect isMulti={true} value={this.state.country} onChange={(t:any)=>{this.setState({country:t})}}></GeoListSelect>
              </div>
              <div className='col'>
                <label>Lang</label>
                <LangListSelect value={this.state.lang} onChange={(t:any)=>{this.setState({lang:t})}}></LangListSelect>
              </div>
              <div className='col'>
                <label>Reset</label>
                <button onClick={()=>{this.setState({
                      status:null,
                      broker:null,
                      partner:null,
                      country:null,
                      email:'',
                      phone:'',
                      from_date:'',
                      to_date:'',
                      user_ip:'',
                      funnel:'',
                      limit:[0, 20]
                    }); setTimeout(()=>{this.GetList('start_load');}, 500)}} className='btn btn-outline-warning form-control'>reset</button>
              </div>
              <div className='col'>
                <label>Filter</label>
                <button onClick={()=>{this.GetList('start_load');}} className='btn btn-outline-primary form-control'><i style={{fontSize:18, margin:0}} className="material-icons text-primary filter_list_alt">&#xe94e;</i> filter</button>
              </div>
            </div>
          </>
        )
      }
      else{
        return(
          <>
            {(getUserData('role') == 'manager' || getUserData('role') == 'admin' || (getUserData('role') == 'brand manager' && getUserData('leads_display') == 'full'))?<div  style={{position:'absolute', marginTop:-28, marginLeft:'auto', marginRight:'auto', left:0, right:0, textAlign:'center'}}>{(this.state.type_filter == 'standart')?<span onClick={()=>{this.SetTypeFilter('anvanced')}} className="badge bg-secondary rounded-pill ms-auto c_p">standart filter</span>:<span onClick={()=>{this.SetTypeFilter('standart')}} className="badge bg-primary rounded-pill ms-auto c_p">advanced filter</span>}</div>:null} 
            {(this.state.type_filter == 'anvanced')?
              <div className='row'>
                <div className='col'>
                  <label>Lead id</label>
                  <input value={this.state.id} onInput={(t)=>{this.setState({id:t.currentTarget.value})}}  className='form-control'></input>
                </div>
                <div className='col'>
                  <label>Broker lead id</label>
                  <input value={this.state.broker_lead_id} onInput={(t)=>{this.setState({broker_lead_id:t.currentTarget.value})}}  className='form-control'></input>
                </div>
                <div className='col'>
                  <label>Campaign</label>
                  <CampaignsSelect onChange={(t:any)=>{this.setState({campaign:t})}}></CampaignsSelect>
                </div>
                <div className='col'>
                  <label>Ftd</label>
                  <Select value={this.state.ftd} onChange={(t:any)=>{this.setState({ftd:t})}} options={[{label:'all', value:null}, {label:'true', value:1}, {label:'false', value:0}]}></Select>
                </div>
                <div className='col'>
                  <label>User ip</label>
                  <input value={this.state.user_ip} onInput={(t)=>{this.setState({user_ip:t.currentTarget.value})}}  className='form-control'></input>
                </div>
              </div>
            :null
            }
            <div className='row'>
              <div className='col'>
                <label>Email</label>
                <input value={this.state.email} onInput={(t)=>{this.setState({email:t.currentTarget.value})}} className='form-control' type={'email'}></input>
              </div>
              <div className='col'>
                <label>Phone</label>
                <input value={this.state.phone} onInput={(t)=>{this.setState({phone:t.currentTarget.value})}}  className='form-control'></input>
              </div>
              {/* <div className='col'>
                <label>User ip</label>
                <input value={this.state.user_ip} onInput={(t)=>{this.setState({user_ip:t.currentTarget.value})}}  className='form-control'></input>
              </div> */}
              <div className='col'>
                <label>Funnel</label>
                <input value={this.state.funnel} onInput={(t)=>{this.setState({funnel:t.currentTarget.value})}}  className='form-control'></input>
              </div>
              <div className='col'>
                <label>From date</label>
                <input value={this.state.from_date} onChange={(t)=>{this.SetCurrectDateTime('from_date', t.currentTarget.value)}}  className='form-control' type={'datetime-local'}></input>
              </div>
              <div className='col'>
                <label>To date</label>
                <input value={this.state.to_date} onChange={(t)=>{this.SetCurrectDateTime('to_date', t.currentTarget.value)}} className='form-control' type={'datetime-local'}></input>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label>Status</label>
                <StatusSelectList isMulti={true} value={this.state.status} onChange={(t:any)=>{this.setState({status:t})}}></StatusSelectList>
              </div>
              <div className='col'>
                <label>Partner</label>
                <PartnersSelect isMulti={true} value={this.state.partner} onChange={(t:any)=>{this.setState({partner:t})}}></PartnersSelect>
              </div>
              <div className='col'>
                <label>Broker</label>
                <BrokersSelect isMulti={true} value={this.state.broker} onChange={(t:any)=>{this.setState({broker:t})}}></BrokersSelect>
              </div>
              <div className='col'>
                <label>Geo</label>
                <GeoListSelect isMulti={true} value={this.state.country} onChange={(t:any)=>{this.setState({country:t})}}></GeoListSelect>  
              </div>
              <div className='col'>
                <label>Reset/Filter/Export</label>
                <div style={{width:'100%'}} className="btn-group">
                  <button onClick={()=>{this.GetList('start_load');}} className="btn btn-outline-primary ">Filter</button>
                  <button onClick={()=>{this.setState({
                      status:null,
                      broker:null,
                      partner:null,
                      country:null,
                      email:'',
                      phone:'',
                      from_date:'',
                      to_date:'',
                      user_ip:'',
                      funnel:'',
                      limit:[0, 20]
                    });setTimeout(()=>{this.GetList('start_load');}, 500)}} className="btn btn-outline-primary text-warning">Reset</button>
                  <button onClick={()=>{this.setState({customModal:true})}} className="btn btn-outline-primary text-success">Export</button>
                </div>
              </div>
            </div>
          </>
        )
      }
    }

    RenderTable = () => {
      if(getUserData('role') == 'partner' || (getUserData('role') == 'brand manager' && getUserData('leads_display') == 'basic') || (getUserData('role') == 'manager' && getUserData('leads_display') == 'basic')){
          return(
            <table className="table text-white text-center">
              <thead>
                <tr>
                  <th>id</th>
                  <th>status</th>
                  <th>ftd</th>
                  <th>ftd date</th>
                  <th>email</th>
                  <th>funnel</th>
                  {/* <th>user ip</th> */}
                  <th>geo & lang</th>
                  <th>date create</th>
                  <th>errors</th>
                </tr>
              </thead>
              <tbody>
                {this.state.table_data.map((item:any) => {
                  let ftd = <span className='text-warning'>false</span>;
                  
                  let lead_error = null;
                  let broker_send_lead = <i  onClick={()=>{notySend('success', 'success!')}} className="material-icons text-success c_p check_circle">&#xe86c;</i>;
                  if(item.ftd == true){
                    ftd = <span className='text-success'>true</span>;
                  }

                  if(item.error != null){
                    lead_error = <i style={{fontSize:20, marginBottom:2}} onClick={()=>{notySend('warning', item.error)}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                  }
                  else{
                    lead_error = <i style={{fontSize:20, marginBottom:2}} onClick={()=>{notySend('success', 'success!')}} className="material-icons text-success c_p check_circle">&#xe86c;</i>;
                  }
                  
                  if(item.broker_send_lead == false){
                    broker_send_lead = <i onClick={()=>{notySend('warning', 'This user has not yet been sent to the broker because the campaign settings have a limit on sending by time, days of the week and time zone (the user will be automatically sent to the broker according to the time frame set in the campaign settings)!')}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                  }
                  
                  return(
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td><span className='c_p text-info' onClick={()=>{this.GetLeadStatusHistory(item.id)}}>{item.status}</span></td>
                      <td>{ftd}</td>
                      <td>{item.ftd_date}</td>
                      <td className='c_p' onClick={()=>{navigator.clipboard.writeText(item.email).then(()=>{notySend('success', 'email copied to clipboard!')})}} >{item.email}</td>
                      <td>{item.funnel}</td>
                      {/* <td>{item.lead_ip}</td> */}
                      <td>{item.country} {'&'} {item.lang}</td>
                      <td>{item.lead_date_create}</td>
                      <td>{lead_error}</td>
                    </tr>
                  )
                })}
                    
              </tbody>
            </table>
          )
      }
      else{
        let bg_fraud_filter = (this.state.fraud_filter == null)?'bg-secondary':(this.state.fraud_filter == true)?'bg-success':'bg-warning';
        let bg_errors_filter = (this.state.errors_filter == null)?'bg-secondary':(this.state.errors_filter == true)?'bg-success':'bg-warning';
        return(
          <table className="table text-white text-center">
            <thead>
              <tr>
                <th>id</th>
                <th>status</th>
                <th>ftd</th>
                <th>date create</th>
                <th>email</th>
                <th>phone</th>
                <th>funnel</th>
                <th>geo & lang</th>
                <th>partner</th>
                <th>broker</th>
                <th><span onClick={()=>{(this.state.fraud_filter == null)?this.setState({fraud_filter:true}):(this.state.fraud_filter == true)?this.setState({fraud_filter:false}):this.setState({fraud_filter:null}); setTimeout(()=>{this.GetList('start_load')},500)}} style={{fontSize:12}} className={"badge "+bg_fraud_filter+" rounded-pill ms-auto c_p"}>fraud</span></th>
                {/* <th><span style={{borderWidth:10, padding:5, borderColor:'red'}}>errors</span></th> */}
                <th><span onClick={()=>{(this.state.errors_filter == null)?this.setState({errors_filter:true}):(this.state.errors_filter == true)?this.setState({errors_filter:false}):this.setState({errors_filter:null}); setTimeout(()=>{this.GetList('start_load')},500)}} style={{fontSize:12}} className={"badge "+bg_errors_filter+" rounded-pill ms-auto c_p"}>errors</span></th>
                <th>show</th>
              </tr>
            </thead>
            <tbody>
              {this.state.table_data.map((item:any) => {
                let ftd = <span className='text-warning'>false</span>;
                let check_frod_ip = null;
                let check_frod_autologin = null;
                let check_frod_referer = null;
                let check_frod_useragent = null;
                let lead_error = null;
                let broker_send_lead = <i  onClick={()=>{notySend('success', 'success!')}} className="material-icons text-success c_p check_circle">&#xe86c;</i>;
                if(item.ftd == true){
                  ftd = <span className='text-success'>true</span>;
                }

                if(item.check_frod_ip == true){
                  check_frod_ip = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('success', 'system check froud ip return\n success!')}} className="material-icons text-success c_p check_circle">&#xe86c;</i>;
                }
                else{
                  check_frod_ip = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('warning', item.check_frod_ip_info)}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                }

                if(item.check_frod_referer == true){
                  check_frod_referer = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('success', 'system check froud referer return\n success!')}} className="material-icons text-success c_p check_circle">&#xe86c;</i>;
                }
                else{
                  check_frod_referer = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('warning', item.check_frod_referer_info)}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                }

                if(item.check_frod_useragent == true){
                  check_frod_useragent = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('success', 'system check froud useragent return\n success!')}} className="material-icons text-success c_p check_circle">&#xe86c;</i>;
                }
                else{
                  check_frod_useragent = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('warning', item.check_frod_useragent_info)}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                }

                if(item.check_frod_autologin == true){
                  check_frod_autologin = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('success', 'system check froud autologin return\n success!')}} className="material-icons text-success c_p check_circle">&#xe86c;</i>;
                }
                else{
                  if(item.check_frod_autologin_info != ''){
                    check_frod_autologin = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('warning', item.check_frod_autologin_info)}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                  }
                  else{
                    check_frod_autologin = <i style={{fontSize:20, marginBottom:4}} onClick={()=>{notySend('warning', 'autologin url postback info undefined!')}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                  }
                }

                if(item.error != null){
                  lead_error = <i style={{fontSize:20, marginBottom:2}} onClick={()=>{notySend('warning', item.error)}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                }
                else{
                  lead_error = <i style={{fontSize:20, marginBottom:2}} onClick={()=>{notySend('success', 'success!')}} className="material-icons text-success c_p check_circle">&#xe86c;</i>;
                }
                
                if(item.broker_send_lead == false){
                  broker_send_lead = <i onClick={()=>{notySend('warning', 'This user has not yet been sent to the broker because the campaign settings have a limit on sending by time, days of the week and time zone (the user will be automatically sent to the broker according to the time frame set in the campaign settings)!')}} className="material-icons text-warning c_p report_problem">&#xe8b2;</i>;
                }
                //for a long email
                let lead_email = <span>{item.email}</span>;
                if(item.email.length > 22){
                  lead_email = <span className='c_p' onClick={()=>{notySend('success', item.email)}}>{item.email.slice(0, 22)+'...'}</span>;
                }

                //for a long ip
                // let lead_ip = <span>{item.lead_ip}</span>;
                // if(item.lead_ip.length > 22){
                //   lead_ip = <span className='c_p' onClick={()=>{notySend('success', item.lead_ip)}}>{item.lead_ip.slice(0, 22)+'...'}</span>;
                // }
                
                return(
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td><span className='c_p text-info' onClick={()=>{this.GetLeadStatusHistory(item.id)}}>{item.status}</span></td>
                    <td>{ftd}</td>
                    <td>{item.lead_date_create}</td>
                    <td className='c_p' onClick={()=>{navigator.clipboard.writeText(item.email).then(()=>{notySend('success', 'email copied to clipboard!')})}}>{lead_email}</td>
                    <td>{item.phone}</td>
                    <td>{item.funnel}</td>
                    <td>{item.country} {'&'} {item.lang}</td>
                    <td><a style={{textDecoration:'none'}} target={'_blank'} href={'edit_user/'+item.partner+'/'}>{item.partner_name}</a></td>
                    <td><a style={{textDecoration:'none'}} target={'_blank'} href={'edit_broker/'+item.broker+'/'}>{item.broker_name}</a></td>
                    <td>{check_frod_ip} {check_frod_autologin} {check_frod_referer} {check_frod_useragent}</td>
                    <td>{lead_error}</td>
                    <td><span className='text-info c_p' onClick={()=>{this.setState({modal_lead_small_info:true, modal_lead_small_info_data:item})}}><i className="material-icons remove_red_eye">&#xe417;</i></span> <a href={'/lead/'+item.id+'/'} className='text-primary'> <i style={{rotate:'90deg'}} className="material-icons arrow_circle_up">&#xf182;</i></a> </td>
                  </tr>
                )
              })}
                  
            </tbody>
          </table>
        )
      }
    }

    RenderDopAreaForSelect = () => {
      if(this.state.table_data.length == 0){
        return(
          <div style={{minHeight:150}}></div>
        )
      }
      else{
        return null;
      }
      
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
              <div className='row'>
                {this.state.total_analyse.map((item:any) => {
                  return(
                    <div key={this.state.total_analyse.indexOf(item)} className='col'>
                      <div className='card' style={{minWidth:126}}>
                        <div className='card-body text-center'>
                          <div className='text-primary'>{item.icon}</div>
                          <div>{item.data}</div>
                          <div>{item.name}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <hr></hr>
              {this.RenderFilter()}
              <hr></hr>

              <div className='table-responsive' style={{fontSize:12}}>
                  {this.RenderTable()}
                  <div>{this.RenderTableLoader()}</div>
                  <br></br>
                  {this.RenderBtnLoadMore()}
                  <br></br>
                  {this.RenderDopAreaForSelect()}
                </div>
            </div>
          </div>
          <CustomModal visible={this.state.customModal} header={'Export'} callback={()=>{this.setState({customModal:false})}}>
            <div className='text-center'>
              <button onClick={()=>{this.ExportLeads()}} className='btn form-control btn-primary'>{(this.state.export_confirm == null) ? <div className="spinner-border text-warning spinner-border-sm"></div>:<span>export to csv</span>}</button>
              {(this.state.export_url != null) ? <> <hr></hr><div className='text-center'><a href={this.state.export_url} download={true}><h5>Download</h5></a></div></>:null}
            </div>
            <br></br>
          </CustomModal>
          <CustomModal visible={this.state.modal_lead_small_info} header={'Lead (id '+this.state.modal_lead_small_info_data.id+')'} size={'large'} callback={()=>{this.setState({modal_lead_small_info:false})}}>
            <div>
              <div className='row'>
                <div className='col'>
                  <div className='card'>
                    <div className='card-body text-center'>
                      <div>campaign</div>
                      <a style={{textDecoration:'none'}} target={'_blank'} href={'/edit_campaign/'+this.state.modal_lead_small_info_data.campaign+'/'}>{this.state.modal_lead_small_info_data.campaign_name}</a>
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='card'>
                    <div className='card-body text-center'>
                      <div>partner</div>
                      <a style={{textDecoration:'none'}} target={'_blank'} href={'/edit_user/'+this.state.modal_lead_small_info_data.partner+'/'}>{this.state.modal_lead_small_info_data.partner_name}</a>
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='card'>
                    <div className='card-body text-center'>
                      <div>broker</div>
                      <a style={{textDecoration:'none'}} target={'_blank'} href={'/edit_broker/'+this.state.modal_lead_small_info_data.broker+'/'}>{this.state.modal_lead_small_info_data.broker_name}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='row'>
                <div className='col'>
                  <label>status</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.status} className='form-control' disabled={true}></input>
                </div>
                <div className='col'>
                  <label>ftd</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.ftd} className='form-control' disabled={true}></input>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label>ftd date</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.ftd_date} className='form-control' disabled={true}></input>
                </div>
                <div className='col'>
                  <label>first_name</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.first_name} className='form-control' disabled={true}></input>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label>last_name</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.last_name} className='form-control' disabled={true}></input>
                </div>
                <div className='col'>
                  <label>email</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.email} className='form-control' disabled={true}></input>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label>phone</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.phone} className='form-control' disabled={true}></input>
                </div>
                <div className='col'>
                  <label>user_ip</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.lead_ip} className='form-control' disabled={true}></input>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label>funnel</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.funnel} className='form-control' disabled={true}></input>
                </div>
                <div className='col'>
                  <label>country</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.country} className='form-control' disabled={true}></input>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label>lang</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.lang} className='form-control' disabled={true}></input>
                </div>
                <div className='col'>
                  <label>lead date create</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.lead_date_create} className='form-control' disabled={true}></input>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label>broker lead id</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.broker_lead_id} className='form-control' disabled={true}></input>
                </div>
                <div className='col'>
                  <label>broker last update date</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.broker_last_update_date} className='form-control' disabled={true}></input>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label>utm comment</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.utm_comment} className='form-control' disabled={true}></input>
                </div>
                <div className='col'>
                  <label>utm content</label>
                  <input defaultValue={this.state.modal_lead_small_info_data.utm_content} className='form-control' disabled={true}></input>
                </div>
              </div>
            </div>
            <hr></hr>
            <a href={'/lead/'+this.state.modal_lead_small_info_data.id+'/'} className='btn btn-primary form-control'><i className="material-icons arrow_circle_up" style={{rotate:'90deg'}}></i></a>
          </CustomModal>
        </div>
      );
    }
}

